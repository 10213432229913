// Witness pie chart control

<template>
  <!-- <div class="smy_contain"> -->
    <!-- <v-container fluid class="pa-0 ma-0 smy_contain"> -->
      <v-data-table
        class="smy_table"
        :loading="loading"
        v-model="selectedInternal"
        :headers="header_meta"
        :items="dataInternal"
        :item-key="itemKey"
        dense
        :sort-by="[this.modeKey]"
        :sort-desc="[true]"
        single-select
        :footer-props="{
          'items-per-page-options': [10, 15, 20, 40, -1]
        }"
        :items-per-page="15"
        height=435
      >
        <template v-slot:[`item.color`]="{ item }">
          <div :style="{'background-color': item['color_' + modeKey], height: '10px', width: '10px', opacity: 1}" class="marker"></div>
          <!-- <v-chip :color="item.color" dark>{{ item[nameKey] }}</v-chip> -->
        </template>
        <template v-slot:[`item.clinicid`]="{ item }">
          <div :title="updateClinicIdToClinicName(item.clinicid)" class="NameItem" style="max-width: 200px;">{{ updateClinicIdToClinicName(item.clinicid) }}</div>
        </template>
        <template v-slot:[`item.average_duration`]="{ item }">
          {{ formatDT(item.average_duration) }}
          <!-- <v-chip :color="item.color" dark>{{ item[nameKey] }}</v-chip> -->
        </template>
        <template v-slot:[`item.total_time`]="{ item }">
          {{ formatDT(item.total_time) }}
          <!-- <v-chip :color="item.color" dark>{{ item[nameKey] }}</v-chip> -->
        </template>
        <template v-if="modeKey === 'count'" v-slot:[`item.percent`]="{ item }">
          <div style="margin-left: 8px;">{{ (item.count / metadata.total_count * 100).toFixed(0) }}</div>
        </template>
        <template v-else-if="modeKey === 'average_duration'" v-slot:[`item.percent`]="{ item }">
          {{ (item.average_duration / metadata.total_average_duration * 100).toFixed(0) }}
        </template>
        <template v-else-if="modeKey === 'total_time'" v-slot:[`item.percent`]="{ item }">
           <div style="margin-left: 8px;">{{ (item.total_time / metadata.total_time_total * 100).toFixed(0) }}</div>
        </template>
        <template v-slot:[`item.witness_point_name`]="{ item }">
          <div class="NameItem" :title="item.witness_point_name">{{ item.witness_point_name }}</div>
        </template>
        <template v-slot:[`item.witness_location_name`]="{ item }">
          <div class="NameItem" :title="item.witness_location_name">{{ item.witness_location_name }}</div>
        </template>
        <template v-slot:[`item.witness_operator_name`]="{ item }">
          <div class="NameItem" :title="item.witness_operator_name">{{ item.witness_operator_name }}</div>
        </template>
        <template v-slot:[`item.clinic_comparison_name`]="{ item }">
          <div class="NameItem" style="max-width: 200px;" :title="item.clinic_comparison_name">{{ item.clinic_comparison_name }}</div>
        </template>
        <template v-if="modeKey === 'count'" v-slot:[`body.append`]>
          <tr v-if="$vuetify.breakpoint.smAndUp">
            <td>
            </td>
            <td v-if="addAdditionalColumn"></td>
            <td><!-- <b>Total:</b> --></td>
            <td class="text-end"><b>{{ metadata['total_count'] }}</b></td>
          </tr>
          <tr v-else>
            <td class="v-data-table__mobile-row">
              <div class="v-data-table__mobile-row__wrapper">
                <div class="v-data-table__mobile-row__header">Total count: </div>
                <div class="v-data-table__mobile-row__cell">
                  <div data-v-fc1cc014="" style="margin-left: 8px;">{{ metadata['total_count'] }}</div>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template v-else-if="modeKey === 'average_duration' && $vuetify.breakpoint.smAndUp" v-slot:[`body.append`]>
          <tr>
            <td>
            </td>
            <td v-if="addAdditionalColumn"></td>
            <td><!-- <b>Total:</b> --></td>
            <td class="text-end"><b>{{ metadata['total_duration_count'] }}</b></td>
            <td class="text-end"><b>{{ formatDT(metadata['total_average_duration']) }}</b></td>
          </tr>
        </template>
        <template v-else-if="modeKey === 'total_time'" v-slot:[`body.append`]="">
          <tr v-if="$vuetify.breakpoint.smAndUp">
            <td>
            </td>
            <td v-if="addAdditionalColumn"></td>
            <td><!-- <b>Total:</b> --></td>
            <td class="text-end"><b>{{ metadata['total_duration_count'] }}</b></td>
            <td class="text-end"><b>{{ formatDT(metadata['total_time_total']) }}</b></td>
          </tr>
          <tr v-else>
            <td class="v-data-table__mobile-row">
              <div class="v-data-table__mobile-row__wrapper">
                <div class="v-data-table__mobile-row__header">Total count: </div>
                <div class="v-data-table__mobile-row__cell">
                  <div data-v-fc1cc014="" style="margin-left: 8px;">{{ metadata['total_duration_count'] }}</div>
                </div>
              </div>
            </td>
            <td class="v-data-table__mobile-row">
              <div class="v-data-table__mobile-row__wrapper">
                <div class="v-data-table__mobile-row__header">Total duration: </div>
                <div class="v-data-table__mobile-row__cell">
                  <div data-v-fc1cc014="" style="margin-left: 8px;">{{ formatDT(metadata['total_time_total']) }}</div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    <!-- </v-container> -->
  <!-- </div> -->
</template>

<style scoped>
.v-data-table >>> td, .v-data-table >>> th {
  padding: 0 !important;
}
* >>> .v-data-table__mobile-row__header {
  width: 100%;
}
* >>> td {
  cursor: pointer;
}
* >>> tr {
  height: 21px;
}
* >>> tr > td, * >>> tr > th {
    height: 25px !important;
}
</style>

<style>
.v-data-footer__pagination {
  margin: 0 10px 0 10px !important;
}
</style>

<script>
import witnessPieLegendComponentModel from '@/component-models/witness-pie-legend-component-model';

export default witnessPieLegendComponentModel;

</script>
