// Witness raw data table

<template>
  <v-card tile class="pa-2" flat>
    <v-card-title class="pt-0 pb-3">{{ cardTitleOverride || cardTitle }}
      <div class="flex-grow-1"></div>
      <h6 v-if="downloadLoading && !loading" class="mr-2">This might take a while...</h6>
      <v-btn v-if="downloadButton" class="icon-lg" title="Data download" text small :loading="downloadLoading || loading" icon :color="downloadColor" @click="downloadClick">
        <v-icon class="icon-lg">$riwIconSet_csv</v-icon>
      </v-btn>
    </v-card-title>
    <div class="smy_legend_padding">
      <v-card-text>
        <v-data-table
          class="smy_table"
          :loading="false"
          :headers="findRelevantDataHeaders"
          :items="dataItems"
          :options.sync="dataOptions"
          :server-items-length="maxLength"
          dense
          :items-per-page="itemsPerPageOverride || 20"
          :footer-props="{
            'items-per-page-options': [20],
            'disable-pagination': this.loading || this.downloadLoading,
            'page-text': this.paginationText
          }"
          :height="tableHeightOverride || 510"
        >
          <template v-slot:[`item.clinicid`]="{ item }">
            <div :title="item.clinicid">{{ updateClinicIdToClinicName(item.clinicid) }}</div>
          </template>
          <template v-slot:[`item.witnesspoint`]="{ item }">
            <div :title="item.witnesspoint"> {{ item.witnesspoint || item.eventtype }}</div>
          </template>
          <template v-slot:[`item.duration`]="{ item }">
            <div :title="item.duration + ' seconds'">{{ formatDuration(item.duration) }}</div>
          </template>
          <template v-slot:[`item.datetime`]="{ item }">
            <div :title="item.datetime">{{ formatISODate(item.datetime) }}</div>
          </template>
          <template v-slot:[`item.start`]="{ item }">
            <div :title="item.start">{{ formatISODate(item.start) }}</div>
          </template>
          <template v-slot:[`item.end`]="{ item }">
            <div :title="item.end">{{ formatISODate(item.end) }}</div>
          </template>
        </v-data-table>
      </v-card-text>
    </div>
  </v-card>
</template>

<style scoped>
.v-data-table >>> td, .v-data-table >>> th {
  padding: 0 !important;
}
* >>> .v-data-table__mobile-row__header {
  width: 100%;
}
.smy_legend {
  margin-top: -25px !important;
}
.smy_legend_padding >>> .v-card__text {
  padding: 0px 4px;
}
* >>> tr > td, * >>> tr > th {
    height: 24px !important;
}
</style>

<style>
.v-data-footer__pagination {
  margin: 0 10px 0 10px !important;
}
</style>

<script>
import witnessRawDataTableComponentModel from '@/component-models/witness-raw-data-table-component-model';

export default witnessRawDataTableComponentModel;

</script>
