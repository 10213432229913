var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"barchart_container"},[(_vm.usingClinicAverages)?_c('div',{staticClass:"d-flex key mr-4"},[_c('div',{staticClass:"silvercircle mt-1"}),_c('div',{staticClass:"ml-1"},[_vm._v("All laboratory")])]):_vm._e(),_c('v-data-table',{staticClass:"smy_table",attrs:{"loading":_vm.loading,"headers":_vm.header_meta,"items":_vm.dataInternal,"item-key":_vm.itemKey,"dense":"","sort-by":[this.modeKey],"sort-desc":[true],"single-select":"","mobile-breakpoint":"1000","footer-props":{
      'items-per-page-options': [10, 15, 20, 40, -1]
    },"items-per-page":15,"height":"415"},scopedSlots:_vm._u([{key:"item.color",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"marker",style:({'background-color': item['color_' + _vm.modeKey], height: '10px', width: '10px', opacity: 1})})]}},{key:"item.clinicid",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"NameItem",staticStyle:{"max-width":"200px"},attrs:{"title":_vm.updateClinicIdToClinicName(item.clinicid)}},[_vm._v(_vm._s(_vm.updateClinicIdToClinicName(item.clinicid)))])]}},{key:"item.average_duration",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDT(item.average_duration))+" ")]}},{key:"item.total_time",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDT(item.total_time))+" ")]}},(_vm.modeKey === 'count')?{key:"item.percent",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s((item.count / _vm.metadata.total_count * 100).toFixed(0))+" ")]}}:(_vm.modeKey === 'average_duration')?{key:"item.percent",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"margin-left":"8px"}},[_vm._v(_vm._s((item.average_duration / _vm.avgDurationSum * 100).toFixed(0)))])]}}:(_vm.modeKey === 'total_time')?{key:"item.percent",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s((item.total_time / _vm.metadata.total_time_total * 100).toFixed(0))+" ")]}}:null,{key:"item.witness_point_name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"NameItem",attrs:{"title":item.witness_point_name}},[_vm._v(_vm._s(item.witness_point_name))])]}},{key:"item.witness_location_name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"NameItem",attrs:{"title":item.witness_location_name}},[_vm._v(_vm._s(item.witness_location_name))])]}},{key:"item.witness_operator_name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"NameItem",attrs:{"title":item.witness_operator_name}},[_vm._v(_vm._s(item.witness_operator_name))])]}},{key:"item.clinic_comparison_name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"NameItem",staticStyle:{"max-width":"200px"},attrs:{"title":item.clinic_comparison_name}},[_vm._v(_vm._s(item.clinic_comparison_name))])]}},{key:"body.append",fn:function(){return [(_vm.$vuetify.breakpoint.smAndUp)?_c('tr',[_c('td'),(_vm.addAdditionalColumn)?_c('td'):_vm._e(),_c('td',{staticClass:"text-end"},[_c('b',[_vm._v(_vm._s(_vm.metadata['total_duration_count']))])]),_c('td',{staticClass:"text-end"},[_c('b',[_vm._v(_vm._s(_vm.formatDT(_vm.metadata['avg_average_duration'])))])])]):_c('tr',[_c('td',{staticClass:"v-data-table__mobile-row"},[_c('div',{staticClass:"v-data-table__mobile-row__wrapper"},[_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v("Total count: ")]),_c('div',{staticClass:"v-data-table__mobile-row__cell"},[_c('div',{staticStyle:{"margin-left":"8px"},attrs:{"data-v-fc1cc014":""}},[_vm._v(_vm._s(_vm.metadata['total_duration_count']))])])])]),_c('td',{staticClass:"v-data-table__mobile-row"},[_c('div',{staticClass:"v-data-table__mobile-row__wrapper"},[_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v("Average: ")]),_c('div',{staticClass:"v-data-table__mobile-row__cell"},[_c('div',{staticStyle:{"margin-left":"8px"},attrs:{"data-v-fc1cc014":""}},[_vm._v(_vm._s(_vm.formatDT(_vm.metadata['avg_average_duration'])))])])])])])]},proxy:true},{key:"item.bars",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"barchartdiv"},[(!item['clinic_average_duration'])?_c('div',{staticClass:"onebar"},[_c('div',{style:('border-radius: 0px; top: 0; left: 0; height: 21px; width: ' + (item['average_duration'] / _vm.metadata.max_average_duration * 100).toString() + '%; background-color: ' + _vm.colorToUse + ';')},[_vm._v(" ")])]):_c('div',{staticClass:"onebar",attrs:{"title":'Avg. All Laboratory: ' + _vm.formatDT(item.clinic_average_duration)}},[_c('div',{style:('border-radius: 0px; top: 0; left: 0; height: 11px; width: ' + (item['average_duration'] / _vm.metadata.max_average_duration * 100).toString() + '%; background-color: rgb(54, 140, 225);')},[_vm._v(" ")]),(item['clinic_average_duration'])?_c('div',{style:('border-radius: 0px; top: 0; left: 0; height: 11px; width: ' + (item['clinic_average_duration'] / _vm.metadata.max_average_duration * 100).toString() + '%; background-color: silver;')},[_vm._v(" ")]):_vm._e()])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }