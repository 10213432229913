// Witness pie chart control

<template>
  <v-card tile class="pa-2 rounded-lg" flat>
    <v-card-title class="pt-0 pb-3">{{ cardTitle }}
      <div class="flex-grow-1"></div>
      <v-btn v-if="(dl_button && data.length > 0) || (dl_button && loading)" title="Data download" text small :loading="loading" icon :color="dl_color" @click="emit_dl">
        <v-icon class="icon-lg">$riwIconSet_csv</v-icon>
      </v-btn>
    </v-card-title>
    <v-overlay
      absolute
      opacity=0
      :value="loading"
    >
      <v-progress-circular
        indeterminate
        size="64"
        :color="dl_color"
      ></v-progress-circular>
    </v-overlay>
    <v-overlay
      absolute
      :value="loading"
    >
    </v-overlay>
    <v-overlay
      z-index="2"
      absolute
      :value="noData"
      opacity="0.2"
    >
      <p style="color: rgba(255, 255, 255, .3); font-size: min(100px, max(6vw, 56px)); font-weight: bold;">
        No Data
      </p>
    </v-overlay>
    <div class="smy_legend_padding">
      <v-card-text>
        <div class="smy_subheading">
        </div>
        <v-container fluid>
          <v-row v-if="modeKey !== 'average_duration'" justify="center" class="">
            <v-col xs=12 md=4 class="pa-0 mb-1" cols="12">
              <vue-plotly v-if="data.length"
              id="pieDiv" 
              :data="pieData"
              :layout="pie_layout"
              :options="pie_options"
              :autoResize="true"
              :watchShallow="true"
              class='plotly_pie'
              @hover="triggerSetSelected"
              ref="crazyPlotly"/>
              <div class="flex-grow-1"></div>
            </v-col>
            <v-col xs=12 md=8 class="fill-height" cols="12">
              <WitnessPieLegend class="smy_legend"
              :loading="false"
              :data="data"
              :metadata="metadata"
              :nameKey="nameKey"
              :itemKey="itemKey"
              :modeKey="modeKey"
              :selected="selected"
              :multiClinic="multiClinic">
              </WitnessPieLegend>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col xs=12 md=12 class="fill-height" cols="12">
              <witness-bar-chart class="smy_legend"
              :loading="false"
              :data="data"
              :metadata="metadata"
              :nameKey="nameKey"
              :itemKey="itemKey"
              :modeKey="modeKey"
              :selected="selected"
              :multiClinic="multiClinic">
              </witness-bar-chart>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </div>
  </v-card>
</template>

<style scoped>
* >>> .hovertext {
  opacity: 0.8
}
* >>> .main-svg {
  cursor: pointer;
}
* >>> .js-plotly-plot .plotly svg {
  overflow: visible !important;
}
</style>

<script>
import witnessPieControlComponentModel from '@/component-models/witness-pie-control-component-model';

export default witnessPieControlComponentModel;

</script>
