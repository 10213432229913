<template>
  <div class="barchart_container">
    <div v-if="usingClinicAverages" class="d-flex key mr-4" style="">
      <div class="silvercircle mt-1"></div><div class="ml-1">All laboratory</div>
    </div>
    <v-data-table
      class="smy_table"
      :loading="loading"
      :headers="header_meta"
      :items="dataInternal"
      :item-key="itemKey"
      dense
      :sort-by="[this.modeKey]"
      :sort-desc="[true]"
      single-select
      mobile-breakpoint="1000"
      :footer-props="{
        'items-per-page-options': [10, 15, 20, 40, -1]
      }"
      :items-per-page="15"
      height=415
    >
      <template v-slot:[`item.color`]="{ item }">
        <div :style="{'background-color': item['color_' + modeKey], height: '10px', width: '10px', opacity: 1}" class="marker"></div>
      </template>
      <template v-slot:[`item.clinicid`]="{ item }">
        <div :title="updateClinicIdToClinicName(item.clinicid)" class="NameItem" style="max-width: 200px;">{{ updateClinicIdToClinicName(item.clinicid) }}</div>
      </template>
      <template v-slot:[`item.average_duration`]="{ item }">
        {{ formatDT(item.average_duration) }}
      </template>
      <template v-slot:[`item.total_time`]="{ item }">
        {{ formatDT(item.total_time) }}
      </template>
      <template v-if="modeKey === 'count'" v-slot:[`item.percent`]="{ item }">
        {{ (item.count / metadata.total_count * 100).toFixed(0) }}
      </template>
      <template v-else-if="modeKey === 'average_duration'" v-slot:[`item.percent`]="{ item }">
        <div style="margin-left: 8px;">{{ (item.average_duration / avgDurationSum * 100).toFixed(0) }}</div>
      </template>
      <template v-else-if="modeKey === 'total_time'" v-slot:[`item.percent`]="{ item }">
        {{ (item.total_time / metadata.total_time_total * 100).toFixed(0) }}
      </template>
      <template v-slot:[`item.witness_point_name`]="{ item }">
        <div class="NameItem" :title="item.witness_point_name">{{ item.witness_point_name }}</div>
      </template>
      <template v-slot:[`item.witness_location_name`]="{ item }">
        <div class="NameItem" :title="item.witness_location_name">{{ item.witness_location_name }}</div>
      </template>
      <template v-slot:[`item.witness_operator_name`]="{ item }">
        <div class="NameItem" :title="item.witness_operator_name">{{ item.witness_operator_name }}</div>
      </template>
      <template v-slot:[`item.clinic_comparison_name`]="{ item }">
        <div class="NameItem" style="max-width: 200px;" :title="item.clinic_comparison_name">{{ item.clinic_comparison_name }}</div>
      </template>
      <template v-slot:[`body.append`]="">
        <tr v-if="$vuetify.breakpoint.smAndUp">
          <td><!-- <b>Total:</b> --></td>
          <td v-if="addAdditionalColumn"></td>
          <td class="text-end"><b>{{ metadata['total_duration_count'] }}</b></td>
          <td class="text-end"><b>{{ formatDT(metadata['avg_average_duration']) }}</b></td>
        </tr>
        <tr v-else>
          <td class="v-data-table__mobile-row">
            <div class="v-data-table__mobile-row__wrapper">
              <div class="v-data-table__mobile-row__header">Total count: </div>
              <div class="v-data-table__mobile-row__cell">
                <div data-v-fc1cc014="" style="margin-left: 8px;">{{ metadata['total_duration_count'] }}</div>
              </div>
            </div>
          </td>
          <td class="v-data-table__mobile-row">
              <div class="v-data-table__mobile-row__wrapper">
                <div class="v-data-table__mobile-row__header">Average: </div>
                <div class="v-data-table__mobile-row__cell">
                  <div data-v-fc1cc014="" style="margin-left: 8px;">{{ formatDT(metadata['avg_average_duration']) }}</div>
                </div>
              </div>
            </td>
        </tr>
      </template>
      <template v-slot:[`item.bars`]="{ item }">
        <div class="barchartdiv">
          <div v-if="!item['clinic_average_duration']" class="onebar" style="">
            <div :style="'border-radius: 0px; top: 0; left: 0; height: 21px; width: ' + (item['average_duration'] / metadata.max_average_duration * 100).toString() + '%; background-color: ' + colorToUse + ';'">&nbsp;</div>
          </div>
          <div v-else class="onebar" style="" :title="'Avg. All Laboratory: ' + formatDT(item.clinic_average_duration)">
            <div :style="'border-radius: 0px; top: 0; left: 0; height: 11px; width: ' + (item['average_duration'] / metadata.max_average_duration * 100).toString() + '%; background-color: rgb(54, 140, 225);'">&nbsp;</div>
            <div v-if="item['clinic_average_duration']" :style="'border-radius: 0px; top: 0; left: 0; height: 11px; width: ' + (item['clinic_average_duration'] / metadata.max_average_duration * 100).toString() + '%; background-color: silver;'">&nbsp;</div>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
* >>> .v-data-table__wrapper {
  overflow-x: hidden;
}
* >>> .v-data-table__mobile-row__cell {
  width: 100%;
}
* >>> .v-data-table__mobile-row__header {
  width: 100%;
}
* >>> td {
  cursor: pointer;
}
/* * >>> tr {
  height: 21px;
} */
* >>> tr > td, * >>> tr > th {
    height: 23px !important;
}
</style>

<script>

import witnessBarChartComponentModel from '@/component-models/witness-bar-chart-component-model';

export default witnessBarChartComponentModel;

</script>
