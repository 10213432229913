var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-2",attrs:{"tile":"","flat":""}},[_c('v-card-title',{staticClass:"pt-0 pb-3"},[_vm._v(_vm._s(_vm.cardTitleOverride || _vm.cardTitle)+" "),_c('div',{staticClass:"flex-grow-1"}),(_vm.downloadLoading && !_vm.loading)?_c('h6',{staticClass:"mr-2"},[_vm._v("This might take a while...")]):_vm._e(),(_vm.downloadButton)?_c('v-btn',{staticClass:"icon-lg",attrs:{"title":"Data download","text":"","small":"","loading":_vm.downloadLoading || _vm.loading,"icon":"","color":_vm.downloadColor},on:{"click":_vm.downloadClick}},[_c('v-icon',{staticClass:"icon-lg"},[_vm._v("$riwIconSet_csv")])],1):_vm._e()],1),_c('div',{staticClass:"smy_legend_padding"},[_c('v-card-text',[_c('v-data-table',{staticClass:"smy_table",attrs:{"loading":false,"headers":_vm.findRelevantDataHeaders,"items":_vm.dataItems,"options":_vm.dataOptions,"server-items-length":_vm.maxLength,"dense":"","items-per-page":_vm.itemsPerPageOverride || 20,"footer-props":{
          'items-per-page-options': [20],
          'disable-pagination': this.loading || this.downloadLoading,
          'page-text': this.paginationText
        },"height":_vm.tableHeightOverride || 510},on:{"update:options":function($event){_vm.dataOptions=$event}},scopedSlots:_vm._u([{key:"item.clinicid",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"title":item.clinicid}},[_vm._v(_vm._s(_vm.updateClinicIdToClinicName(item.clinicid)))])]}},{key:"item.witnesspoint",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"title":item.witnesspoint}},[_vm._v(" "+_vm._s(item.witnesspoint || item.eventtype))])]}},{key:"item.duration",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"title":item.duration + ' seconds'}},[_vm._v(_vm._s(_vm.formatDuration(item.duration)))])]}},{key:"item.datetime",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"title":item.datetime}},[_vm._v(_vm._s(_vm.formatISODate(item.datetime)))])]}},{key:"item.start",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"title":item.start}},[_vm._v(_vm._s(_vm.formatISODate(item.start)))])]}},{key:"item.end",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"title":item.end}},[_vm._v(_vm._s(_vm.formatISODate(item.end)))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }